// @flow
import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

import styles from './404.module.scss';

const NotFoundPage = () => (
  <Layout noBG>
    <SEO title="404: Not found" />
    <section className={styles.wrapper}>
      <div className={styles.messages}>
        <h2>404 お探しのページが見つかりませんでした</h2>
        <nav>
          <Link to="/">Paidy.comホームに戻る</Link>
          <OutboundLink
            href="https://my.paidy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MyPaidyホームに戻る
          </OutboundLink>
          <OutboundLink
            href="https://cs-support.paidy.com/support/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            よくあるご質問
          </OutboundLink>
          <OutboundLink
            href="https://cs-support.paidy.com/support/tickets/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問合せフォーム
          </OutboundLink>
        </nav>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
